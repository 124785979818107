<template>
  <div>
    <iframe
      :src="commercialSignUrl"
      frameborder="0"
      width="100%"
      height="700px"
      style="margin-top: 70px"
    ></iframe>
  </div>
</template>

<script>
export default {
  created() {
    this.commercialSignUrl = this.$storage.getItem('commercialSignUrl')
    this.handleSuccess()
  },
  data() {
    return {
      commercialSignUrl: '',
    }
  },
  beforeDestroy() {
    this.destroyFn()
  },
  methods: {
    handleSuccess() {
      this.destroyFn = window.jpmt.listenIframeMessageOnce('contract', () => {
        console.log('签约成功')
        this.$message.success('签约成功')
        this.$router.push('/bill-financing')
      })
    },
  },
}
</script>
